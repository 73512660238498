import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import ReactHtmlParser from 'react-html-parser'
import { useIntl, FormattedMessage } from 'react-intl'
import Layout from '../components/Layout'
import FlexibleContent from '../components/FlexibleContent'
import BackgroundPageImage from '../components/BackgroundPageImage'

export const JobPositionTemplate = ({ title, acf, date }) => {
  // recruiter_name
  // recruiter_email
  // recruiter_phone_number
  const recruiterName =
    acf.recruiter_name != null && acf.recruiter_name !== 'tyhja' ? (
      <div className="recruiter-name">{acf.recruiter_name}</div>
    ) : (
      ''
    )
  const recruiterEmail =
    acf.recruiter_email != null && acf.recruiter_email !== 'tyhja' ? (
      <div className="recruiter-email"><a href={`mailto:${acf.recruiter_email}`}>{acf.recruiter_email}</a></div>
    ) : (
      ''
    )
  const recruiterPhone =
    acf.recruiter_phone_number != null &&
    acf.recruiter_phone_number !== 'tyhja' ? (
      <div className="recruiter-phone">{acf.recruiter_phone_number}</div>
    ) : (
      ''
    )

  const intl = useIntl()

  const lang = intl.locale
  const backLink = lang !== 'en' ? `/${lang}/rekry` : '/jobs'

  return (
    <article className="job-position">
      <div className="section flex constrained narrow job-position-header">
        <Link to={backLink} className="arrow-back back-link">
          <span className="icon-arrow-left" />
          <FormattedMessage id="back_to_open_positions" />
        </Link>
        <h1>{title}</h1>
        <span className="publish-date">{date}</span>
      </div>
      <FlexibleContent data={acf.flexible_content_job_position} />
      <div className="section constrained narrow job-position-footer">
        <h2>
          <FormattedMessage id="more_information" />
        </h2>
        {recruiterName}
        {recruiterEmail}
        {recruiterPhone}
      </div>
    </article>
  )
}

JobPositionTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const JobPosition = ({ data }) => {
  const { wordpressWpJobPosition: jobPosition } = data
  const { title, lang, translations, date, acf } = jobPosition
  const { image, position } = acf

  const imageBackground =
    typeof image !== 'undefined' && image !== null ? image : false
  const imagePosition =
    typeof position !== 'undefined' && position !== null ? position : false
  const displayBackgroundImage =
    imageBackground && imagePosition ? (
      <BackgroundPageImage image={imageBackground} position={imagePosition} />
    ) : (
      ''
    )

  const parsedTitle = ReactHtmlParser(title)

  return (
    <Layout lang={lang} translations={translations}>
      <Helmet title={`${parsedTitle} | Don & Branco`} />
      {displayBackgroundImage}
      <JobPositionTemplate title={parsedTitle} acf={acf} date={date} />
    </Layout>
  )
}

JobPosition.propTypes = {
  data: PropTypes.object.isRequired,
}

export default JobPosition

export const pageQuery = graphql`
  query GetJobPositionById($id: String!) {
    wordpressWpJobPosition(id: { eq: $id }) {
      id
      status
      date(formatString: "DD.MM.YYYY")
      title
      path
      lang
      translations {
        en
        fi
      }
      acf {
        recruiter_name
        recruiter_email
        recruiter_phone_number

        flexible_content_job_position {
          __typename
          ... on Node {
            ... on WordPressAcf_wysiwyg {
              class_name
              wysiwyg
            }

            ... on WordPressAcf_two_images {
              class_name
              image_sizes
              first_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2560, quality: 100) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }

              second_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2560, quality: 100) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }

            ... on WordPressAcf_text_in_columns {
              class_name
              columns {
                column_title
                column_title_link
                lines {
                  text
                }
              }
            }

            ... on WordPressAcf_image {
              class_name
              full_width_image
              image_size
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2560, quality: 100) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }

            ... on WordPressAcf_links_to_references {
              class_name
              references_page_link
              references {
                link
                link_text
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 2560, quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }

            ... on WordPressAcf_video {
              class_name
              video_file {
                localFile {
                  url
                }
              }
              full_width_video
            }

            ... on WordPressAcf_video_and_image {
              class_name
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2560, quality: 100) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
              video {
                localFile {
                  url
                }
              }
              alignment
            }

            ... on WordPressAcf_staff {
              class_name
              peeps {
                title
                name
                tel
                email
              }
            }

            ... on WordPressAcf_video_embed {
              class_name
              url
            }
          }
        }
      }
    }
  }
`
